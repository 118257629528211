import React from 'react';
import CenterClass from '../components/center/centerClass';
import ExTicket from '../components/center/exTicket';
import SpeClass from '../components/center/speClass';
import Counseling from '../components/center/counseling';
import End from '../components/common/end';

const center = () => (
  <div>
    <CenterClass />
    <SpeClass />
    <ExTicket />
    <Counseling />
    <End />
  </div>
);

export default center;
