import React from 'react';
import course from '../../assets/images/course-main.png';
import c1 from '../../assets/images/course-1.png';
import c2 from '../../assets/images/course-2.png';
import c3 from '../../assets/images/course-3.png';
import c4 from '../../assets/images/course-4.png';

const gyrotonic = '자이로토닉Ⓡ';
const gyrokinesis = '자이로키네시스Ⓡ';
const Gyrokinesis = 'GYROKINESISⓇ';

function Qualification() {
  return (
    <div className="centerIntro align-items-center py-5" id="center" style={{ marginLeft: 10, marginRight: 10 }}>
      <div className="d-flex pb-5">
        <div className="col-lg-2 col-sm-1 sm-col-1" />
        <div className="col">
          <div className="f-36 f-md-26 f-700 mb-3">
            <span className="gyro">{gyrotonic}</span>
            {' '}
            •
            {' '}
            <span className="gyro">{gyrokinesis}</span>
            {' '}
            <span className="gyro-e">{Gyrokinesis}</span>
            {' '}
            강사 과정
          </div>
          <div className="d-lg-flex d-md-flex d-sm-block my-4 my-md-1 my-sm-1">
            <img
              src={course}
              alt=""
              className="text-center mt-lg-3 me-lg-5 me-md-5 me-sm-0
                      h-420 w-400 h-md-360 h-sm-250 w-sm-100p"
            />
            <div className="">
              <div className="f-30 f-md-16 f-700 mt-3">미국 본사 정식 교육 기관</div>
              <div className="f-30 f-md-16 f-400 mt-2">Training course</div>
              <div className="text-sub f-16 f-md-14 f-400 mt-lg-4 mt-sm-3 w-75">
                마포 유일 정식
                {' '}
                <span className="gyro">{gyrotonic}</span>
                {' '}
                •
                {' '}
                <span className="gyro">{gyrokinesis}</span>
                {' '}
                교육 센터인
                {' '}
                <span className="gyro">{gyrotonic}</span>
                {' '}
                애니핏은
                Pre-trainer인 원장님과 Julio 직속 Master Trainer의 강사 과정이 운영되는
                교육 기관입니다.
                <br />
                <br />
                미국
                {' '}
                <span className="gyro">{gyrotonic}</span>
                {' '}
                본사에 인증된 강사에게 사전 교육 후 교육 등록이 가능합니다.
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                교육 뿐 아니라 다양한 스터디와 강사만을 위한 수업도 진행되오니 '교육 일정' 페이지에서
                교육 일정을 확인하시기 바라며, 예비 강사님들은 아래 링크를 통해 사전 레슨을 먼저 신청부탁드립니다.
              </div>
              <div className="mt-4 w-80p w-xs-100p">
                <a href=" https://m.booking.naver.com/booking/13/bizes/474604?area=pll&service-target=map-pc#">
                  <button
                    type="button"
                    className="btn-custom bg-navy text-white f-16 f-700"
                  >
                    사전레슨 등록하기
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-center">
              <img src={c1} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">사전 교육</div>
            </div>
            <div className="col text-center">
              <img src={c2} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">예비 교육</div>
            </div>
            <div className="col text-center">
              <img src={c3} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">본 교육</div>
            </div>
            <div className="col text-center">
              <img src={c4} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">견습생 리뷰</div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-sm-1 sm-col-1" />
      </div>
    </div>
  );
}

export default Qualification;
