import React from "react";
import courseImg from "../../../../assets/images/course-gyrotonic2.png";

const gyrotonic = "자이로토닉Ⓡ";
const Gyrotonic = "GYROTONICⓇ";

const gyrotonic2 = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      <span className="gyro">{gyrotonic} </span>
      애니핏은&nbsp;
      <span className="gyro">{gyrotonic}</span>
      본사로부터 강사 자격증 발급 및 자격 과정 교육 운영을 인증받은
      <span className="gyro">{gyrotonic}</span>
      전문 기관입니다.
      <br />
      모든 교육은 사전 교육비 입금 후 등록이 확정되며 교육 날짜 및 시간은
      공지사항을 통해 확인 바랍니다.
    </div>
    <img src={courseImg} alt="" className="mt-4 mx-auto w-100p" />
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            1. 예비 교육 &nbsp;
            <span className="gyro-e">{Gyrotonic}</span>
            &nbsp; Level 2, Program 1, 2 Pre-Training Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            Julio Harvath가 인증한 Lv2 마스터 트레이너가 지도함
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 45만원 &nbsp; 교육비/ 525 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 15시간(3일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/&nbsp;
                <span className="gyro">{gyrotonic}</span> 라이센스가 유효한 Lv1
                인증강사에 한함
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>Lv1의 기본 원리와 난이도 있는 동작을 배웁니다.</span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            2. 본 교육 &nbsp;
            <span className="gyro-e">{Gyrotonic}</span>
            &nbsp;Lv2 Program 1, 2 Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            Julio Harvath가 인증한 Lv2 마스터 트레이너가 지도함
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 55만원 &nbsp; 교육비/ 700 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 20시간(4일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/&nbsp;
                <span className="gyro">{gyrotonic}</span> Lv2 예비교육과정
                이수증서가 있는 자에 한함
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                본 교육 과정에서는 강사 자격증은 발행되지 않으며 참가증서가
                주어집니다.
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                본 교육 과정은 예비교육 후 12개월 이내에 참여해야만 합니다.
              </span>
            </li>
          </ul>
          <div className="text-sub f-14 mt-2 ms-3">
            *예비교육 후 12개월 이내에 참여해야만 유효함
          </div>
        </li>
      </ul>
    </div>
  </div>
);

export default gyrotonic2;
