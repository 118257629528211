import React from "react";
import center from "../../assets/images/center-main.png";
import c1 from "../../assets/images/c1.png";
import c2 from "../../assets/images/c2.png";
import c3 from "../../assets/images/c3.png";
import c4 from "../../assets/images/c4.png";

const gyrotonic = "자이로토닉Ⓡ";
const gyrokinesis = "자이로키네시스Ⓡ";

function CenterClass() {
  return (
    <div
      className="centerIntro align-items-center py-5"
      id="center"
      style={{ marginLeft: 10, marginRight: 10 }}
    >
      <div className="d-flex pb-5">
        <div className="col-lg-2 col-sm-1 sm-col-1" />
        <div className="col">
          <div className="f-36 f-md-26 f-700 mb-3">애니핏 센터수업</div>
          <div className="d-lg-flex d-md-flex d-sm-block my-4 my-md-1 my-sm-1">
            <img
              src={center}
              alt=""
              className="text-center mt-lg-3 me-lg-5 me-md-5 me-sm-0
                      h-420 w-400 h-md-360 h-sm-250 w-sm-100p"
            />
            <div className="">
              <div className="f-30 f-md-16 f-700 mt-3">
                정규 강사의 소수정예 수업
              </div>
              <div className="f-30 f-md-16 f-400 mt-2">1:1 • 2:1 • 4:1</div>
              <div className="text-sub f-16 f-md-14 f-400 mt-lg-4 mt-sm-3 w-75">
                애니핏의 수업은 소수 정예로 이루어집니다. 자격증을 취득한
                정규강사는 수업을 리드하며 한 분 한 분의 움직임에 집중하여
                티칭합니다.
                <br />
                <br />
                애니핏은 마포 최초 국제{" "}
                <span className="gyro">{gyrotonic}</span> 교육기관으로 5년동안
                한자리에서 많은 분들의 사랑을 받았습니다.
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-center">
              <img src={c1} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">레슨 상담</div>
            </div>
            <div className="col text-center">
              <img src={c2} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">
                <span className="gyro">{gyrotonic}</span> 개인 • 4:1
              </div>
            </div>
            <div className="col text-center">
              <img src={c3} alt="" className="w-100p wh-sm-130 mt-3" />
              <div className="f-18 f-md-14 f-700 mt-2">
                <span className="gyro">{gyrokinesis}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-sm-1 sm-col-1" />
      </div>
    </div>
  );
}

export default CenterClass;
