import React from 'react';
import Main from '../components/home/1 main';
import Link from '../components/home/2 list';
import Gyrotonic from '../components/home/3 gyrotonic';
import CenterClass from '../components/home/CenterClass';
import Review from '../components/home/4 review';
import Photos from '../components/home/photos';
import Contact from '../components/common/contact';

const home = () => (
  <div>
    <Main />
    <Link />
    <Gyrotonic />
    <CenterClass />
    <Review />
    <Photos />
    <Contact />
  </div>
);

export default home;
