import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import gyrotonicImg from "../../assets/images/class-tonic.png";
import gyrokinesisImg from "../../assets/images/class-kinesis.png";
import classicPilates from "../../assets/images/class-fila.png";

const gyrotonic = "자이로토닉Ⓡ";
const gyrokinesis = "자이로키네시스Ⓡ";

function imageSlide() {
  const settings = {
    className: "slider-items",
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div>
      <div className="slider-wrapper py-5 w-60p w-sm-90p mx-auto">
        <Slider {...settings}>
          <div className="slider-items">
            <img src={gyrotonicImg} alt="" className="w-100p" />
            <div className="mt-3 w-80p w-xs-100p">
              <div className="f-20 f-700">
                <span className="gyro">{gyrotonic}</span>
              </div>
              <ul
                className="
            d-lg-flex d-md-flex d-sm-flex sm-d-block ps-1 mt-3 f-15 f-500"
              >
                <li className="ms-2 me-2 li-center">5:1 그룹수업</li>
                <li className="mx-2 li-center">2:1 듀엣수업</li>
                <li className="mx-2 li-center">1:1 개인수업</li>
              </ul>
            </div>
            <Link to="/center">
              <button
                type="button"
                className="btn-custom bg-navy text-white f-16 f-700"
              >
                센터수업 보러가기
              </button>
            </Link>
          </div>
          <div className="slider-items">
            <img src={gyrokinesisImg} alt="" className="w-100p" />
            <div className="mt-3 w-80p w-xs-100p">
              <div className="f-20 f-700">
                <span className="gyro">{gyrokinesis}</span>
              </div>
              <ul
                className="
            d-lg-flex d-md-flex d-sm-flex sm-d-block ps-1 mt-3 f-15 f-500"
              >
                <li className="ms-2 me-2 li-center">그룹수업</li>
              </ul>
            </div>
            <Link to="/center">
              <button
                type="button"
                className="btn-custom bg-navy text-white f-16 f-700"
              >
                센터수업 보러가기
              </button>
            </Link>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default imageSlide;
