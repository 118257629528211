import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const gyrotonic = '자이로토닉Ⓡ';
const gyrokinesis = '자이로키네시스Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';
const Gyrokinesis = 'GYROKINESISⓇ';
const GyroSystem = 'GYROTONIC EXPANSION SYSTEMⓇ';
const Gyrotoner = 'GYROTONERⓇ';
const n = '& Logo,';

function Footer() {
  return (
    <div className="bg-footer text-white py-3">
      <div className="d-flex my-auto align-items-center justify-content-center">
        <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
        <div className="col">
          <div className="row d-flex pt-4">
            <div className="col f-16 f-sm-14 f-700">
              <span className="gyro">{gyrotonic}</span>
              {' '}
              애니핏
            </div>
            <div className="col text-end">
              <a href="https://www.instagram.com/gyrotonic_anyfit">
                <FontAwesomeIcon icon={faInstagram} className="text-white" style={{ height: 25 }} />
              </a>
            </div>
          </div>
          <div className="col mt-3 d-lg-flex d-sm-block">
            <div className="col f-400 f-14 f-sm-12 text-lg-end text-sm-start d-lg-none d-sm-block">
              <span className="gyro">{gyrotonic}</span>
              ,&nbsp;
              <span className="gyro">{gyrotonic}</span>
              와 로고,&nbsp;
              <span className="gyro">{gyrokinesis}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotonic}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotonic}</span>
              {' '}
              <br />
              <span className="gyro-e">{n}</span>
              {' '}
              <span className="gyro-e">{GyroSystem}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotoner}</span>
              {' '}
              and&nbsp;
              <br />
              <span className="gyro-e">{Gyrokinesis}</span>
              는 Gyrotonic Sales Corp의 등록 상표이며 허가를 받아 사용됩니다.
            </div>

            <div className="col text-footer f-400 f-14 f-sm-12">
              <ul className="p-0 mt-3">
                <li>상호: 자이로토닉 애니핏</li>
                <li>대표: 오영미</li>
                <li>사업자번호: 519-27-00267</li>
                <li>주소: 서울시 마포구 백범로 74 3층</li>
                <li>T: 010-7498-6636</li>
                <li>E: gyrotonicanyfit@gmail.com</li>
              </ul>
            </div>

            <div className="col f-400 f-14 f-sm-12 text-lg-end text-sm-start sm-d-none d-sm-none d-lg-block my-auto">
              <span className="gyro">{gyrotonic}</span>
              ,&nbsp;
              <span className="gyro">{gyrotonic}</span>
              와 로고,&nbsp;
              <span className="gyro">{gyrokinesis}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotonic}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotonic}</span>
              {' '}
              <br />
              <span className="gyro-e">{n}</span>
              {' '}
              <span className="gyro-e">{GyroSystem}</span>
              ,&nbsp;
              <span className="gyro-e">{Gyrotoner}</span>
              {' '}
              and&nbsp;
              <br />
              <span className="gyro-e">{Gyrokinesis}</span>
              는 Gyrotonic Sales Corp의 등록 상표이며 허가를 받아 사용됩니다.
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
      </div>
    </div>
  );
}

export default Footer;
