import React, { useState } from 'react';
import Gyrotonic1 from '../couseContents/gyrotonic1';
import Gyrotonic2 from '../couseContents/gyrotonic2';
import Gyrokinesis1 from '../couseContents/gyrokinesis1';
import Gyrokinesis2 from '../couseContents/gyrokinesis2';
import Special from '../couseContents/special';
import Update from '../couseContents/update';

const gyrotonic = '자이로토닉Ⓡ';
const gyrokinesis = '자이로키네시스Ⓡ';

function Detail() {
  const [tab, setTab] = useState(1);

  return (
    <div>
      <div>
        <ul className="d-lg-flex sm-d-block p-0 justify-content-between">
          <li className="nav-item">
            <button type="button" className={tab === 1 ? 'nav-link p-1 active' : 'nav-link p-1'} onClick={() => setTab(1)}>
              <span className="gyro">{gyrotonic}</span>
              {' '}
              &nbsp; Lv1
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link p-1" onClick={() => setTab(2)}>
              <span className="gyro">{gyrotonic}</span>
              {' '}
                &nbsp; Lv2
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link p-1" onClick={() => setTab(3)}>
              <span className="gyro">{gyrokinesis}</span>
              {' '}
              &nbsp; Lv1
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link p-1" onClick={() => setTab(4)}>
              <span className="gyro">{gyrokinesis}</span>
              {' '}
              &nbsp; Lv2
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link p-1" onClick={() => setTab(5)}>
              스페셜머신 교육과정
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className="nav-link p-1" onClick={() => setTab(6)}>
              업데이트 코스
            </button>
          </li>
        </ul>
      </div>

      <div className="contents">
        {tab === 1 && <Gyrotonic1 />}
        {tab === 2 && <Gyrotonic2 />}
        {tab === 3 && <Gyrokinesis1 />}
        {tab === 4 && <Gyrokinesis2 />}
        {tab === 5 && <Special />}
        {tab === 6 && <Update />}
      </div>
    </div>
  );
}

export default Detail;
