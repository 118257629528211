import React from 'react';
import m1 from '../../assets/images/m1.png';
import m2 from '../../assets/images/m2.png';
import m3 from '../../assets/images/m3.png';
import m4 from '../../assets/images/m4.png';

const master = () => (
  <div className="centerIntro align-items-center py-5" id="center" style={{ marginLeft: 10, marginRight: 10 }}>
    <div className="d-flex pb-5">
      <div className="col-lg-2 col-sm-1 sm-col-1" />
      <div className="col">
        <div className="f-36 f-md-26 f-700 mb-3">마스터 강사</div>
        <div className="row mt-3">
          <div className="col text-center">
            <img src={m1} alt="" className="w-100p wh-sm-130 mt-3" />
          </div>
          <div className="col text-center">
            <img src={m2} alt="" className="w-100p wh-sm-130 mt-3" />
          </div>
          <div className="col text-center">
            <img src={m3} alt="" className="w-100p wh-sm-130 mt-3" />
          </div>
          <div className="col text-center">
            <img src={m4} alt="" className="w-100p wh-sm-130 mt-3" />
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-sm-1 sm-col-1" />
    </div>
  </div>
);

export default master;
