import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo192.png';

function Nav() {
  const [isToggled, setIsToggled] = useState(false);

  const gyrotonic = '자이로토닉Ⓡ';
  const gyrokinesis = '자이로키네시스Ⓡ';

  return (
    <div className="bg-navy ">
      <nav className="navBar px-3 d-flex justify-content-sm-between
      justify-content-md-between justify-content-xs-between
      justify-content-lg-around align-items-center "
      >
        <div className="navBar-logo p-2 my-auto">
          <Link to="/">
            <img src={logo} alt="" width="30px" height="30px" />
          </Link>
        </div>
        <div className="navBar-menus d-lg-flex d-md-none d-sm-none
          xs-none justify-content-around my-auto"
        >

          <Link to="/" className="mx-4 a-light f-18 f-500">홈</Link>
          <a href="https://blog.naver.com/PostList.naver?blogId=gyrotonicmapo&from=postList&categoryNo=18" className="mx-4 a-light f-18 f-500">공지사항</a>
          <Link to="/instructor" className="mx-4 a-light f-18 f-500">애니핏</Link>
          <Link
            to="/course"
            id="dropdownMenu"
            data-bs-toggle="dropdown"
            className="mx-4 a-light f-18 f-500"
          >
            강사과정
          </Link>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu">
            <Link to="/course" className="dropdown-item f-16 f-400">강사과정</Link>
            <Link to="/course/gyrotonic1" className="dropdown-item f-16 f-400">
              <span className="gyro">
                {gyrotonic}
                {' '}
              </span>
              Lv1
            </Link>
            <Link to="/course/gyrotonic2" className="dropdown-item f-16 f-400">
              <span className="gyro">
                {gyrotonic}
                {' '}
              </span>
              Lv2
            </Link>
            <Link to="/course/gyrokinesis1" className="dropdown-item f-16 f-400">
              <span className="gyro">{gyrokinesis}</span>
              {' '}
              Lv1
            </Link>
            <Link to="/course/gyrokinesis2" className="dropdown-item f-16 f-400">
              <span className="gyro">{gyrokinesis}</span>
              {' '}
              Lv2
            </Link>
            <Link to="/course/special" className="dropdown-item f-16 f-400">스페셜머신 교육과정</Link>
            <Link to="/course/update" className="dropdown-item f-16 f-400">업데이트 코스</Link>
          </ul>

          <Link
            to="/center"
            className="mx-4 a-light f-18 f-500"
          >
            클래스
          </Link>
        </div>
        <div className="d-lg-flex d-md-none d-sm-none xs-none " />
        <button type="button" className="d-lg-none btn-none" onClick={() => setIsToggled(!isToggled)}>
          {isToggled ? <FontAwesomeIcon icon={faXmark} className="text-white" />
            : <FontAwesomeIcon icon={faBars} className="text-white" />}
        </button>
      </nav>
      { isToggled && (
      <div>
        <ul className="toggle-menus d-lg-none justify-content-around m-0 ps-4">
          <li className="toggle-menus-menu py-2">
            <Link to="/" className="mx-4 a-light f-18 f-500">홈</Link>
          </li>
          <li className="toggle-menus-menu py-2">
            <a href="https://blog.naver.com/PostList.naver?blogId=gyrotonicmapo&from=postList&categoryNo=18" className="mx-4 a-light f-18 f-500">공지사항</a>
          </li>
          <li className="toggle-menus-menu py-2">
            <Link to="/instructor" className="mx-4 a-light f-18 f-500">애니핏</Link>
          </li>
          <li className="toggle-menus-menu py-2 ">
            <Link
              to="/course"
              id="dropdownMenu"
              data-bs-toggle="dropdown"
              className="mx-4 a-light f-18 f-500"
            >
              강사과정
            </Link>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu">
              <Link to="/course" className="dropdown-item f-16 f-400" state={{ y: 2400 }}>강사과정</Link>
              <Link to="/course/gyrotonic1" className="dropdown-item f-16 f-400">
                <span className="gyro">{gyrotonic}</span>
                {' '}
                Lv1
              </Link>
              <Link to="/course/gyrotonic2" className="dropdown-item f-16 f-400">
                <span className="gyro">{gyrotonic}</span>
                {' '}
                Lv2
              </Link>
              <Link to="/course/gyrokinesis1" className="dropdown-item f-16 f-400">
                <span className="gyro">{gyrokinesis}</span>
                {' '}
                Lv1
              </Link>
              <Link to="/course/gyrokinesis2" className="dropdown-item f-16 f-400">
                <span className="gyro">{gyrokinesis}</span>
                {' '}
                Lv2
              </Link>
              <Link to="/course/special" className="dropdown-item f-16 f-400">스페셜머신 교육과정</Link>
              <Link to="/course/update" className="dropdown-item f-16 f-400">업데이트 코스</Link>
            </ul>
          </li>
          <li className="toggle-menus-menu py-2">
            <Link to="/center" className="mx-4 a-light f-18 f-500">클래스</Link>
          </li>
        </ul>
      </div>
      )}
    </div>
  );
}

export default Nav;
