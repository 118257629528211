import React from 'react';
import styled from 'styled-components';

const GyroKo = styled.header`
 font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif"; 
`;

export default function gyroKo() {
  return (
    <GyroKo>자이로토닉Ⓡ GYROTONICⓇ</GyroKo>
  );
}
