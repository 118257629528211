import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../../assets/images/photo-1.png';
import img2 from '../../assets/images/photo-2.png';
import img3 from '../../assets/images/photo-3.png';
import img4 from '../../assets/images/photo-4.png';
import img5 from '../../assets/images/photo-5.png';
import img6 from '../../assets/images/photo-6.png';

function Slide() {
  const settings = {
    className: 'slider-items',
    infinite: true,
    speed: 5000,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
  };
  return (
    <div>
      <div className="slider-wrapper w-80p py-5 mx-auto">
        <Slider {...settings}>
          <div className="slider-items">
            <img src={img1} alt="" className="m-3" style={{ width: 300 }} />
          </div>
          <div className="slider-items">
            <img src={img2} alt="" className="m-3" style={{ width: 300 }} />
          </div>
          <div className="slider-items">
            <img src={img3} alt="" className="m-3" style={{ width: 300 }} />
          </div>
          <div className="slider-items">
            <img src={img4} alt="" className="m-3" style={{ width: 300 }} />
          </div>
          <div className="slider-items">
            <img src={img6} alt="" className="m-3" style={{ width: 300 }} />
          </div>
          <div className="slider-items">
            <img src={img5} alt="" className="m-3" style={{ width: 300 }} />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Slide;
