import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Back from '../../assets/images/bg-gyro.png';

const gyroImage = {
  backgroundImage: `url(${Back})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'noRepeat',
};

const gyrotonic = '자이로토닉Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';

const main = () => (
  <div className="gyrotonic d-flex py-5" style={gyroImage}>
    <div className="col-lg-2 col-sm-1 sm-col-1" />
    <div className="row mx-auto my-0 align-items-center justify-content-center">
      <div className="text-white">
        <div className="my-3 text-center f-36 f-sm-30 f-700">
          <span className="gyro-e">{Gyrotonic}</span>
        </div>
        <div className="f-16 f-400 w-400 w-xs-95p mx-auto">
          <span className="gyro">{gyrotonic}</span>
          은 유니크하면서도 치료적인 접근의 운동 시스템입니다.
          <span className="gyro">{gyrotonic}</span>
          을 통해 건강함과 유연성있는 척추, 관절의 안정성 및 움직임의 확장을 기대할 수 있습니다.
          숙련된 국제 인증 강사를 통해 회원에게 맞는 맞춤 수업을 진행합니다.
        </div>
        <hr className="hr" />
        <div className="mt-4 f-16 f-700 w-xs-95p">다음 교육 일정</div>
        <ul className="ps-3 mt-2 f-16 f-500 w-xs-95p">
          <li className="li-gyro">아래 링크에서 교육 일정을 확인할 수 있습니다.</li>
        </ul>
        <a
          href="https://blog.naver.com/PostList.naver?blogId=gyrotonicmapo&from=postList&categoryNo=18"
          target="_self"
          className="d-flex justify-content-center"
        >
          <button type="button" className="btn-custom bg-white text-navy my-3">
            <div className="d-flex justify-content-center align-items-center">
              <p className="my-0 f-16 f-700">
                교육일정 확인
              </p>
              <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
            </div>
          </button>
        </a>
      </div>
    </div>
    <div className="col-lg-2 col-sm-1 sm-col-1" />
  </div>
);

export default main;
