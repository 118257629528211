import React from "react";
import courseImg from "../../../../assets/images/course-gyrokinesis1.png";

const gyrotonic = "자이로토닉Ⓡ";
const gyrokinesis = "자이로키네시스Ⓡ";
const Gyrokinesis = "GYROKINESISⓇ";

const gyrokinesis1 = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      <span className="gyro">{gyrotonic} </span>
      애니핏은&nbsp;
      <span className="gyro">{gyrotonic}</span>
      본사로부터 강사 자격증 발급 및 자격 과정 교육 운영을 인증받은
      <span className="gyro">{gyrotonic}</span>
      전문 기관입니다.
      <br />
      모든 교육은 사전 교육비 입금 후 등록이 확정되며 교육 날짜 및 시간은
      공지사항을 통해 확인 바랍니다.
    </div>
    <img src={courseImg} alt="" className="mt-4 mx-auto w-100p" />
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            1. 교육 전 준비 수업 Prerequisites Training
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">사전레슨/ 10시간</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">사전수업비 10시간 30만원</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                <span className="gyro">{gyrokinesis}</span> 운동 경험이 없는
                사람을 위한 기초수업
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            2. 예비 교육 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp;Pre-Training Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span> 국제본부에 등록되어 있는
            마스터트레이너 또는 프리 트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 33만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간(6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/&nbsp;
                <span className="gyro">{gyrokinesis}</span> 사전 경험이 있는 자
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                교육내용/&nbsp;
                <span className="gyro">{gyrokinesis}</span>의 원리와 그룹군의
                이해, 몸의 움직임 향상, homework 을 배웁니다.
              </span>
            </li>
          </ul>
          <div className="text-sub f-14 mt-2 ms-3">
            *예비교육 후 12주 이내에 본 교육에 참여해야 함
          </div>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            3. 본 교육 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp;Foundation Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span>
            국제본부에 등록되어 있는 마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 95만원 &nbsp; 교육비/ 1,575 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 45시간(9일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                교육내용/&nbsp;
                <span className="gyro">{gyrokinesis}</span>의 그룹군의 이해와
                지도법을 배우는 과정
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                동작의 이해도를 높이며 실전에서 티칭할 수 있는 기술을 배웁니다.
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                파운데이션과정 이후 견습생이 되며 실습시간(15시간)을
                가져야합니다.
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            4. 견습생 리뷰 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp; Apprenticeship
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span>
            국제본부에 등록되어 있는 마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 45만원 &nbsp; 교육비/ 525 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 15시간(3일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/&nbsp;
                <span className="gyro">{gyrokinesis}</span> 본교육과정
                이수증서가 있고 최소 3개월&15번의 클래스를 가르친 견습생에 한함
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                Part1: 마스터 트레이너의 감독하의 견습생이 2가지 클래스 포맷에
                따라 수업 티칭(60분&90분)
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                Part2: 동작의 분석 및 원리, Hands On(자세교정) 리뷰 및 운동의
                효과적인 수행에 대한 이해
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            5. 인증 과정 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp;Final Certificate Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span>
            국제본부에 등록되어 있는 마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 45만원 &nbsp; 교육비/ 525 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 15시간(3일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/&nbsp;
                <span className="gyro">{gyrokinesis}</span> 리뷰 교육과정을
                마치고 본 교육 후 6개월의 견습 기간이 지난 견습생
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                인증과정 후 인증강사가 되면 프리교육 전 사전교육&준비수업을 할
                수 있는 인증 강사가 됩니다.
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

export default gyrokinesis1;
