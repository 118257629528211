import React from 'react';
import centerImg from '../../assets/images/inst-center.png';
import studio from '../../assets/images/inst-1.png';
import studio2 from '../../assets/images/inst-2.png';
import studio3 from '../../assets/images/inst-3.png';
import studio4 from '../../assets/images/inst-4.png';

const gyrotonic = '자이로토닉Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';

const center = () => (
  <div className="centerIntro align-items-center py-5" id="center" style={{ marginLeft: 10, marginRight: 10 }}>
    <div className="d-flex pb-5">
      <div className="col-lg-2 col-sm-1 sm-col-1" />
      <div className="col">
        <div className="f-36 f-md-26 f-700 mb-3">센터 소개</div>
        <div className="d-lg-flex d-md-flex d-sm-block my-4 my-md-1 my-sm-1">
          <img
            src={centerImg}
            alt=""
            className="text-center mt-lg-3 me-lg-5 me-md-5 me-sm-0
                      h-420 w-400 h-md-360 h-sm-250 w-sm-100p"
          />
          <div className="">
            <div className="f-30 f-md-16 f-700 mt-3">
              <span className="gyro">{gyrotonic}</span>
              {' '}
              애니핏
              <span className="f-16 f-400"> Since 2016</span>
            </div>
            <div className="f-30 f-md-16 f-400 mt-2">
              <span className="gyro-e">{Gyrotonic}</span>
              &nbsp;
              Anyfit
            </div>
            <div className="text-sub f-16 f-md-14 f-400 mt-lg-4 mt-sm-3">
              미국
              {' '}
              <span className="gyro">{gyrotonic}</span>
              {' '}
              본사 인증 센터로서 강사 교육 과정을 진행하는 스튜디오입니다.
              <br />
              <br />
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              'Wake Your Body'이라는 운동 철학으로 운동을 통해 여러분의 몸을 깨우고자 합니다. 수업을 통해 경험해보세요.
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-center">
            <img src={studio} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-18 f-md-14 f-700 mt-2">센터 내부</div>
          </div>
          <div className="col text-center">
            <img src={studio2} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-18 f-md-14 f-700 mt-2">
              {gyrotonic}
              {' '}
              기구
            </div>
          </div>
          <div className="col text-center">
            <img src={studio3} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-18 f-md-14 f-700 mt-2">필라테스 기구</div>
          </div>
          <div className="col text-center">
            <img src={studio4} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-18 f-md-14 f-700 mt-2">클래식 매트</div>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-sm-1 sm-col-1" />
    </div>
  </div>
);

export default center;
