import React from 'react';
import t1 from '../../assets/images/t1.png';
import t2 from '../../assets/images/t2.png';
import t3 from '../../assets/images/t3.png';

function ExTicket() {
  return (
    <div className="py-5">
      <div className="d-flex centerIntro align-items-center py-lg-5 py-md-3 py-sm-3">
        <div className="col-2 out-mg" />
        <div className="col inner">
          <div className="f-36 f-md-26 f-700">애니핏 체험권</div>
          <div className="row mt-4">
            <div className="col">
              <a href="https://smartstore.naver.com/anyfitpilates/products/7024345921">
                <img src={t1} alt="" className="mx-auto d-block mx-2 my-5 w-245" />
              </a>
            </div>
            <div className="col">
              <a href="https://smartstore.naver.com/anyfitpilates/products/6772015261">
                <img src={t2} alt="" className="mx-auto d-block mx-2 my-5 w-245" />
              </a>
            </div>
            <div className="col">
              <a href="https://smartstore.naver.com/anyfitpilates/products/7024348340">
                <img src={t3} alt="" className="mx-auto d-block mx-2 my-5 w-245" />
              </a>
            </div>
          </div>
          <div className="text-sub f-14">*클릭하면 결제 화면으로 이동합니다.</div>
        </div>
        <div className="col-2 out-mg" />
      </div>
    </div>
  );
}

export default ExTicket;
