import React from 'react';

const gyrotonic = '자이로토닉Ⓡ';
const gyrokinesis = '자이로키네시스Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';
const Gyrokinesis = 'GYROKINESISⓇ';
const Gyrotoner = 'GYROTONERⓇ';

const update = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      모든
      {' '}
      <span className="gyro">{gyrotonic}</span>
      {' '}
      강사들은 최소 1개의 업데이트 교육에(CEC) 참여해서 2년마다 라이센스를 갱신해야합니다.
      {' '}
      <br />
      만약
      {' '}
      <span className="gyro">{gyrotonic}</span>
      과
      {' '}
      <span className="gyro">{gyrokinesis}</span>
      {' '}
      자격을 모두 갖춘 강사라면 둘 중 1개의 교육에만 참여해도 라이센스를 갱신할 수 있습니다.
    </div>
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            1.&nbsp;
            <span className="gyro-e">{Gyrotonic}</span>
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotoner}</span>
                {' '}
                Program 1,2 Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Cardiovascular Breathing Course- Progression 1,2,3
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                The&nbsp;
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Method- Lost and Found
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Level 1 Foundation Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Archway Level 1,2Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Jumping-Stretching Board Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Leg Extension Unit Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Dancers Level 1,2
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications For Equestrians
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for the Fascial Body
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Healing and Rejuvenating the Hands and Feet
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for High Level Athletes
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for the Hip and Knee
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Juliu’s Wrapping System
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Martial Arts
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Osteoporosis
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for the Pelvic Girdle
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Runners
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Scoliosis
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for the Shoulder Girdle
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Exploration into the Extremes
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Level 1 as a Musical Journey
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Level 1 Update Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Level 2, Program 1,2 Pre-Training Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Level 2, Program 1,2 Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Pathways of the Shoulders, Hips, and Spine
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Pre and Post Natal Applications
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Principles Applied to Dynamic Trunk Stabilization
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Principles in the Context of Osteopathy
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Psoas Principles
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Rejuvenation Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Wingmaster Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrotonic}</span>
                {' '}
                Applications for Paraplegic Spinal Cord Injuries
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            2.&nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Cardiovascular Breathing Course- Progression 1,2,3
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Application for Tango Dancers
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Level 1 Foundation Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Archway Level 1, 2 Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Jumping-Stretching Board Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Leg Extension Unit Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Applications for Healing and Rejuvenating the Hands and Feet
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Breathing Course Intensive
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Level 2 Beginner Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Essentials
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Level 2 Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Happy Moves Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Lotus Blossom, Program 1, 2 Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Pre-Trainer Update Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Rejuvenation Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Level 1 Update Course
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Applications for Children- Wonders of the Zoo
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                Jewels of the
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Method
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                Sacred Geometry and the
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Method
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">check_circle</span>
              <span>
                <span className="gyro-e">{Gyrokinesis}</span>
                {' '}
                Applications for Pelvic Floor Health
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

export default update;
