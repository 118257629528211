import React from 'react';
import Center from '../components/instructor/center';
import Director from '../components/instructor/director';
import Master from '../components/instructor/master';
import End from '../components/common/end';

const instructor = () => (
  <div>
    <Center />
    <Director />
    <Master />
    <End />
  </div>
);

export default instructor;
