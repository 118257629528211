import React, { useEffect } from 'react';
import Detail from '../inC/containBtnFiles/btn5';
import Qualification from '../qualification';
import End from '../../common/end';

function Special() {
  useEffect(() => {
    document.getElementById('course').click();
  }, []);

  return (
    <div>
      <Qualification />
      <a href="#course" className="d-flex py-5 text-black" id="course">
        <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
        <div className="col py-3" style={{ marginLeft: 10, marginRight: 10 }}>
          <a className="f-36 f-md-26 f-700 text-black" name="course">
            <div className="pb-4">강사과정</div>
          </a>
          <Detail />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
      </a>
      <End />
    </div>
  );
}

export default Special;
