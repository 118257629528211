import React from "react";
import courseImg from "../../../../assets/images/course-gyrokinesis2.png";

const gyrotonic = "자이로토닉Ⓡ";
const gyrokinesis = "자이로키네시스Ⓡ";
const Gyrokinesis = "GYROKINESISⓇ";

const gyrokinesis2 = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      <span className="gyro">{gyrotonic} </span>
      애니핏은&nbsp;
      <span className="gyro">{gyrotonic}</span>
      본사로부터 강사 자격증 발급 및 자격 과정 교육 운영을 인증받은
      <span className="gyro">{gyrotonic}</span>
      전문 기관입니다.
      <br />
      모든 교육은 사전 교육비 입금 후 등록이 확정되며 교육 날짜 및 시간은
      공지사항을 통해 확인 바랍니다.
    </div>
    <img src={courseImg} alt="" className="mt-4 mx-auto w-100p" />
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            1. 비기너 코스 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp; Level 2 Beginner Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            Julio Harvath가 인증한 Lv2 마스터 트레이너가 지도함
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 75만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간(6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신청자격/ &nbsp;
                <span className="gyro">{gyrokinesis}</span> 라이센스가 유효한
                인증강사 / 인증과정 이후 4개월이 지난 자
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>레벨1보다 더 당야한 호흡과 움직임을 배웁니다.</span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            2. 레벨2 코스 &nbsp;
            <span className="gyro-e">{Gyrokinesis}</span>
            &nbsp;Level 2 Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            Julio Harvath가 인증한 Lv2 마스터 트레이너가 지도함
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 95만원 &nbsp; 교육비/ 1,575 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 45시간(9일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                <span className="gyro">{gyrokinesis}</span> 라이센스가 유효한
                인증강사
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                신체적, 정신적으로 더 깊이 자신을 수련하는 과정입니다.
              </span>
            </li>
          </ul>
          <div className="text-sub f-14 mt-2 ms-3">
            *비기너 코스 후 12개월 이내에 참여해야만 유효함
          </div>
          <div className="text-sub f-14 mt-2 ms-3">
            *본 교육 과정에서는 인증서는 발급되지 않습니다.
          </div>
        </li>
      </ul>
    </div>
  </div>
);

export default gyrokinesis2;
