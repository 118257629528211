import React from 'react';
import '../../assets/styles/main.css';

const Gyrotonic = 'GYROTONICⓇ';

const main = () => (
  <div className="d-flex bg-img-main bg-sm-img-main">
    <div className="col-lg-2 col-sm-1 sm-col-1" />
    <div className="col d-flex align-items-center h-500">
      <div className="text-white f-36 f-sm-30 f-700 my-auto">
        <span>Wake up Your Body</span>
        <br />
        <span className="gyro-e">{Gyrotonic}</span>
        <span> Anyfit</span>
      </div>
    </div>
    <div className="col-lg-2 col-sm-1 sm-col-1" />
  </div>
);

export default main;
