import React from 'react';
import Qualification from '../components/course/qualification';
import InstructorCourse from '../components/course/instructorCourse';
import End from '../components/common/end';

const course = () => (
  <div>
    <Qualification />
    <InstructorCourse />
    <End />
  </div>
);

export default course;
