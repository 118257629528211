import React from 'react';
import bgImg from '../../assets/images/bgInstruct.png';

const bgImage = {
  backgroundImage: `url(${bgImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'noRepeat',
};

const gyrotonic = '자이로토닉Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';

const end = () => (
  <div className="contact" style={bgImage}>
    <div className="d-flex mx-auto my-0 align-items-center justify-content-center h-260 h-sm-150">
      <div className="f-26 f-sm-16 f-700 text-white text-center me-3">
        서울 마포 유일
        {' '}
        <span className="gyro">{gyrotonic}</span>
        {' '}
        <br />
        <span className="gyro-e">{Gyrotonic}</span>
        {' '}
        전문 교육기관
        <br />
        {' '}
        {' '}
        <span className="gyro">{gyrotonic}</span>
        {' '}
        애니핏
      </div>
    </div>
  </div>
);

export default end;
