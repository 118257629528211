import React from 'react';
import Slide from './slide';

function Photos() {
  return (
    <div className="">
      <div className="col p-0">
        <Slide />
      </div>
    </div>
  );
}

export default Photos;
