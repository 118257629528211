import React from 'react';
import Detail from './inC/containBtnFiles/detail';

function InstructorCourse() {
  return (
    <div className="d-flex py-5" id="course">
      <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
      <div className="col py-3" style={{ marginLeft: 10, marginRight: 10 }}>
        <div className="f-36 f-md-26 f-700 pb-4">강사과정</div>
        <Detail />
      </div>
      <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
    </div>
  );
}

export default InstructorCourse;
