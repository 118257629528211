import React from "react";

const gyrotonic = "자이로토닉Ⓡ";
const gyrokinesis = "자이로키네시스Ⓡ";
const Gyrotonic = "GYROTONICⓇ";

function SpeClass() {
  return (
    <div className="d-flex bg-gray centerIntro align-items-center py-5">
      <div className="col-2 out-mg" />
      <div className="col inner py-lg-5 py-md-3 py-sm-3">
        <div className="f-36 f-md-26 f-700">애니핏의 특별한 클래스</div>
        <div className="row mt-3">
          <div className="text-sub f-18 f-md-14 f-400">
            <span className="gyro">{gyrotonic}</span>
            &nbsp;애니핏은 본부에서 인증한 정식 교육 기관으로&nbsp;
            <span className="gyro">{gyrotonic}</span>
            &nbsp;클래스를 운영합니다. 전 강사진은 티칭하는 클래스에 맞는 강사
            자격을 갖추었으며 그 자격을 유지하기 위한 지속적인 교육과 트레이닝을
            받고 있습니다. 본사에서 인증된&nbsp;
            <span className="gyro-e">{Gyrotonic}</span>
            &nbsp;기구를 사용하고 있습니다.
            <br />
            <br />
            소그룹으로 운영하는 애니핏의 클래스는 강사의 50분 집중 교육을 받을
            수 있는 1:1 개인레슨부터 1:2 듀엣레슨, 5:1 그룹 수업으로 운영됩니다.
          </div>
          <div className="mt-4">
            <ul className="f-20 f-md-16 f-500 ps-2">
              <li className="li-basic">
                클래스 종류:
                <span className="gyro">{gyrotonic}</span> /
                <span className="gyro">{gyrokinesis}</span> / 강사 Only 클래스 /
                강사 스터디
              </li>
              <li className="li-basic">
                클래스 인원: 개인레슨 / 듀엣레슨 / 그룹레슨
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-2 out-mg" />
    </div>
  );
}

export default SpeClass;
