import React from 'react';
import dicImg from '../../assets/images/director.png';
import d1 from '../../assets/images/director-1.png';
import d2 from '../../assets/images/director-2.png';
import d3 from '../../assets/images/director-3.png';
import d4 from '../../assets/images/director-4.png';

const gyrotonic = '자이로토닉Ⓡ';
const gyrokinesis = '자이로키네시스Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';
const Gyrokinesis = 'GYROKINESISⓇ';
const gyrotoner = '자이로토너Ⓡ';

const center = () => (
  <div className="bg-gray centerIntro align-items-center py-5" id="center" style={{ marginLeft: 10, marginRight: 10 }}>
    <div className="d-flex pb-5">
      <div className="col-lg-2 col-sm-1 sm-col-1" />
      <div className="col">
        <div className="f-36 f-md-26 f-700 mb-3">원장 소개</div>
        <div className="d-lg-flex d-md-flex d-sm-block my-4 my-md-1 my-sm-1">
          <img
            src={dicImg}
            alt=""
            className="text-center mt-lg-3 me-lg-5 me-md-5 me-sm-0
                      h-420 w-400 h-md-360 h-sm-250 w-sm-100p"
          />
          <div className="">
            <div className="f-30 f-md-16 f-700 mt-3">원장 오영미</div>
            <div className="f-30 f-md-16 f-400 mt-2">Yougnmi Oh</div>
            <div className="text-sub f-16 f-md-14 f-400 mt-lg-3 mt-sm-3 w-75">
              단국대학교 운동처방재활학과를 졸업하고 15년 넘게 티칭을 하고 있습니다. 현재
              {' '}
              <span className="gyro">{gyrotonic}</span>
              {' '}
              및
              {' '}
              <span className="gyro">{gyrokinesis}</span>
              {' '}
              국제 인증 강사로써 강사 자격증 코스 운영 및 교육을 하고 있습니다.
              <ul className="m-0 mt-4 ps-3">
                <li className="li-basic">
                  <span className="gyro-e">{Gyrotonic}</span>
                  {' '}
                  Lv1 - Lv2 Full Certification Course
                </li>
                <li className="li-basic">
                  <span className="gyro-e">{Gyrokinesis}</span>
                  {' '}
                  Lv1 - Lv2 Full Certification Course
                </li>
                <li className="li-basic">
                  <span className="gyro-e">{Gyrotonic}</span>
                  {' '}
                  Pre-Trainer
                </li>
                <li className="li-basic">
                  <span className="gyro-e">{Gyrokinesis}</span>
                  {' '}
                  Pre-Trainer
                </li>
                <li className="li-basic">Polestar Pilates Lv1 - Lv6</li>
                <li className="li-basic">The Root Classic Pilates Certificate</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-center">
            <img src={d1} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-20 f-md-14 f-700 mt-2">점핑 스트레칭 보드</div>
          </div>
          <div className="col text-center">
            <img src={d2} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-20 f-md-14 f-700 mt-2">
              <span className="gyro">{gyrotoner}</span>
            </div>
          </div>
          <div className="col text-center">
            <img src={d3} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-20 f-md-14 f-700 mt-2">점핑 스트레칭 보드</div>
          </div>
          <div className="col text-center">
            <img src={d4} alt="" className="w-100p wh-sm-130 mt-3" />
            <div className="f-20 f-md-14 f-700 mt-2">
              <span className="gyro">{gyrotonic}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-sm-1 sm-col-1" />
    </div>
  </div>
);

export default center;
