import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Gyrotonic1 from '../couseContents/gyrotonic1';
import Gyrotonic2 from '../couseContents/gyrotonic2';
import Gyrokinesis1 from '../couseContents/gyrokinesis1';
import Gyrokinesis2 from '../couseContents/gyrokinesis2';
import Special from '../couseContents/special';
import Update from '../couseContents/update';

function Detail() {
  return (
    <div>
      <Tabs defaultActiveKey="5" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="0" title="자이로토닉 Lv1">
          <Gyrotonic1 />
        </Tab>
        <Tab eventKey="1" title="자이로토닉 Lv2">
          <Gyrotonic2 />
        </Tab>
        <Tab eventKey="2" title="자이로키네시스 Lv1">
          <Gyrokinesis1 />
        </Tab>
        <Tab eventKey="3" title="자이로키네시스 Lv2">
          <Gyrokinesis2 />
        </Tab>
        <Tab eventKey="4" title="스페셜머신 교육과정">
          <Special />
        </Tab>
        <Tab eventKey="5" title="업데이트 코스">
          <Update />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Detail;
