import React from 'react';
import kakao from '../../assets/images/kakao.png';

function Counseling() {
  return (
    <div className="bg-gray py-5">
      <div className="d-flex centerIntro align-items-center py-lg-5 py-md-3 py-sm-3">
        <div className="col-2 out-mg" />
        <div className="col inner">
          <div className="f-36 f-md-26 f-700">
            애니핏에서 상담받아보세요
            <a href="http://pf.kakao.com/_pcLbd">
              <img src={kakao} alt="" className="ms-4" style={{ width: 60, height: 60 }} />
            </a>
          </div>
          <div className="text-sub mt-4 f-18 f-md-14 f-400">
            애니핏 원장님께 직접 상담받아보세요
            <br />
            <br />
            어떤 운동이 내게 잘 맞을지, 어떤 수업을 예약해야 할지 고민되신다면 애니핏 카카오채널로 상담을 신청해보세요.
            <br />
            <br />
            상담 후 1회 체험을 통해 운동을 시작할 수 있게 결정하실 수 있습니다.
          </div>
        </div>
        <div className="col-2 out-mg" />
      </div>
    </div>
  );
}

export default Counseling;
