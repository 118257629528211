import React from 'react';
import { Link } from 'react-router-dom';
import vector from '../../assets/images/Vector 1.png';
import center from '../../assets/images/link-inst.png';
import course from '../../assets/images/link-course.png';
import centerClass from '../../assets/images/link-center.png';

const gyrotonic = '자이로토닉Ⓡ';
const Gyrotonic = 'GYROTONICⓇ';
const gyrokinesis = '자이로키네시스Ⓡ';

const ll = () => (
  <div className="d-flex">
    <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
    <div className="col row py-5">
      <div className="col py-3">
        <div className="w-245 mx-auto">
          <img src={center} alt="애니핏센터" className="h-260" />
          <div className="mt-3 mb-2 text-black f-20 f-700">
            애니핏 센터
            <span className="f-16 f-400"> Since 2016</span>
          </div>
          <p className="f-16 f-400 text-sub w-245 box" style={{ width: 240 }}>
            미국
            {' '}
            <span className="gyro">{gyrotonic}</span>
            {' '}
            본사 인증 센터로 교육 과정을 진행하는 전문 교육 센터 입니다.
          </p>
          <Link to="/instructor" className="d-flex a-dark f-14 f-400">
            더보기
            <img src={vector} alt="" width="40" height="8" className="ms-2 mt-2" />
          </Link>
        </div>
      </div>
      <div className="col py-3">
        <div className="w-245 mx-auto">
          <img src={course} alt="자이로토닉 강사 과정" className="h-260" />
          <div className="mt-3 mb-2 text-black f-20 f-700">
            <span className="gyro">
              {gyrotonic}
              {' '}
            </span>
            <span className="gyro-e">
              {Gyrotonic}
              {' '}
            </span>
            강사 과정
          </div>
          <p className="f-16 f-400 text-sub w-245 box" style={{ width: 240 }}>
            마포 유일  정식 교육 센터로 창시자 Julio의 직속 제자인 Master Trainer가 교육을 진행합니다.
          </p>
          <Link to="/course" className="d-flex a-dark f-14 f-400">
            더보기
            <img src={vector} alt="" width="40" height="8" className="ms-2 mt-2" />
          </Link>
        </div>
      </div>
      <div className="col py-3">
        <div className="w-245 mx-auto">
          <img src={centerClass} alt="센터 수업" className="h-260" />
          <div className="link-title mt-3 mb-2 text-black f-20 f-700">센터 수업</div>
          <p className="f-16 f-400 text-sub w-245 box" style={{ width: 240 }}>
            본사에서 자격을 취득한 정규 강사가 수업하는
            {' '}
            <span className="gyro">{gyrotonic}</span>
            ,
            {' '}
            <span className="gyro">{gyrokinesis}</span>
            , 클래식 필라테스 수업을 진
          </p>
          <Link to="/center" className="d-flex a-dark f-14 f-400">
            더보기
            {' '}
            <img src={vector} alt="" width="40" height="8" className="ms-2 mt-2" />
          </Link>
        </div>
      </div>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-1 sm-col-1" />
  </div>
);

export default ll;
