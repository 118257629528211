import React from "react";
import s1 from "../../../../assets/images/s1.png";
import s2 from "../../../../assets/images/s2.png";
import s3 from "../../../../assets/images/s3.png";
import s4 from "../../../../assets/images/s4.png";

const Gyrotonic = "GYROTONICⓇ";

const special = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      모든 과정은 Lv1 인증 강사에게만 신청 자격이 주어집니다.
      <br />
      스페셜머신 교육과정을 이수한 자는 국제 인증강사의 자격을 유지 및 연장하기
      위한&nbsp;
      <span className="gyro-e">{Gyrotonic}</span>
      &nbsp; Lv1 업데이트 코스에 준하는 자격을 부여받습니다.
    </div>
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">1. 자이로토너 P1/P2</div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 18시간 (각 3일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 45만원 &nbsp; 교육비/ 630 USD
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">2. 점핑 스트레칭 보드</div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 35시간 (7일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 80만원 &nbsp; 교육비/ 1,225 USD
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">3. 레그 익스텐션 유닛</div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간 (6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 75만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">4. 아치웨이 Lv1/Lv2</div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간 (각 6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 75만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
          </ul>
        </li>
      </ul>

      <div className="row mt-5">
        <div className="col text-center">
          <img src={s1} alt="" className="w-100p wh-sm-130 mt-3" />
          <div className="f-14 f-700 mt-3">자이로토너</div>
        </div>
        <div className="col text-center">
          <img src={s2} alt="" className="w-100p wh-sm-130 mt-3" />
          <div className="f-14 f-700 mt-3">레그익스텐션</div>
        </div>
        <div className="col text-center">
          <img src={s3} alt="" className="w-100p wh-sm-130 mt-3" />
          <div className="f-14 f-700 mt-3">점핑스트레칭보드</div>
        </div>
        <div className="col text-center">
          <img src={s4} alt="" className="w-100p wh-sm-130 mt-3" />
          <div className="f-14 f-700 mt-3">아치웨이</div>
        </div>
      </div>
    </div>
  </div>
);

export default special;
