import React from 'react';
import bgImg from '../../assets/images/bgFooter.png';
import kakao from '../../assets/images/kakao.png';

const bgImage = {
  backgroundImage: `url(${bgImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'noRepeat',
};

const contact = () => (
  <div className="contact" style={bgImage}>
    <div className="d-flex mx-auto my-0 align-items-center justify-content-center h-260 h-sm-150">
      <div className="f-26 f-sm-16 f-700 text-white me-3">
        더 궁금하신 사항은
        <br />
        {' '}
        애니핏에 문의해보세요.
      </div>
      <a href="http://pf.kakao.com/_pcLbd">
        <img src={kakao} alt="" className="ms-3" style={{ width: 60, height: 60 }} />
      </a>
    </div>
  </div>
);

export default contact;
