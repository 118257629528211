import React from "react";
import courseImg from "../../../../assets/images/course-gyrotonic1.png";

const gyrotonic = "자이로토닉Ⓡ";
const Gyrotonic = "GYROTONICⓇ";

const gyrotonic1 = () => (
  <div>
    <div className="f-16 f-400 ms-1 mt-4 mx-auto">
      <span className="gyro">{gyrotonic} </span>
      애니핏은&nbsp; <span className="gyro">{gyrotonic}</span> 본사로부터 강사
      자격증 발급 및 자격 과정 교육 운영을 인증받은{" "}
      <span className="gyro">{gyrotonic}</span> 전문 기관입니다. <br />
      모든 교육은 사전 교육비 입금 후 등록이 확정되며 교육 날짜 및 시간은
      공지사항을 통해 확인 바랍니다.
    </div>
    <img src={courseImg} alt="" className="mt-4 mx-auto w-100p" />
    <div className="mx-auto">
      <ul className="ps-1">
        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            1. 교육 전 준비 수업 Prerequisites Training
          </div>
          <ul className="text-sub mt-2 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700 fw-bold">사전레슨/ 최소 10시간</span>
            </li>

            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">사전등록비 10시간 40만원</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              <span>
                <span className="gyro">{gyrotonic}</span>
                &nbsp; 운동 경험이 없는 사람을 위한 수업입니다.
              </span>
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            2. 예비 교육&nbsp;
            <span className="gyro-e">{Gyrotonic}</span> Pre-Training Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span> 국제본부에 등록되어 있는
            마스터트레이너 또는 프리 트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-3 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 33만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간 (6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              신청자격/ 사전교육이 10회 이상 진행된 강사
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              교육내용/ <span className="gyro">{gyrotonic}</span>의 원리와 몸의
              움직임 향상과 기구의 사용방법을 배웁니다.
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              예비 교육 후 12주 이내에 본 교육에 참여해야 함
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            {" "}
            3. 본 교육&nbsp;
            <span className="gyro-e">{Gyrotonic}</span> Foundation Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span> &nbsp;국제본부에 등록되어
            있는 마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-3 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 115만원 &nbsp; 교육비/ 2,100 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 60시간 (12일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              신청자격/ <span className="gyro">{gyrotonic}</span> 예비교육과정
              이수 강사 (최대 12주안에 본교육을 참여해야 합니다.)
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              교육내용/ 운동 동작에 이해도를 높이며 실전에서 티칭할 수 있는
              기술을 배웁니다.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; 파운데이션과정 이후 견습생이 되며
              실습시간(30시간)을 가져야 합니다.
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            {" "}
            4. 견습생 리뷰 &nbsp;
            <span className="gyro-e">{Gyrotonic}</span> Apprenticeship
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span> 국제본부에 등록되어있는
            마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-3 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 75만원 &nbsp; 교육비/ 1,050 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 30시간 (6일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              지원자격/ 본교육을 이수하고 최소 3개월&실습 30시간을 충족한 연습생
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              교육내용/ 본교육 이후 실전에서 놓쳤던 동작 또는 티칭을 조금 더
              다듬고 운동의 이해도를 높이며
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; 실전에 티칭 스킬을 업그레이드 하는
              과정입니다.
            </li>
          </ul>
        </li>

        <li className="mt-5">
          <div className="f-24 f-md-20 f-500">
            5. 인증 과정&nbsp;
            <span className="gyro-e">{Gyrotonic}</span> Final Certificate Course
          </div>
          <div className="text-sub f-14 mt-0 ms-4">
            <span className="gyro">{gyrotonic}</span> 국제본부에 등록되어있는
            마스터트레이너가 지도하는 교육과정
          </div>
          <ul className="text-sub mt-3 ps-3 f-18 f-md-16 w-95p">
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                monetization_on
              </span>
              <span className="f-700">
                등록비/ 45만원 &nbsp; 교육비/ 525 USD
              </span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                schedule
              </span>
              <span className="f-700">교육시간&기간/ 15시간 (3일)</span>
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              신청자격/ 리뷰 교육과정을 마치고 본교육이후 최소 6개월의
              견습기간이 지난 견습생
            </li>
            <li className="mt-1">
              <span className="material-symbols-outlined f-16 f-500 pe-2">
                check_circle
              </span>
              인증과정 후 인증강사가 되면 프리교육 전 사전 교육 & 준비 수업을 할
              수 있는 인증 강사가 됩니다.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

export default gyrotonic1;
