import React from 'react';

const gyrotonic = '자이로토닉Ⓡ';

const review = () => (
  <div className="row mx-auto my-0 align-items-center justify-content-center" style={{ backgroundColor: '#F8F8F8' }}>
    <div className="col-2 sm-none" />
    <div className="col">
      <div className="text-black pt-5">
        <div className="text-center f-36 f-700 f-md-26">
          애니핏을 이용한 회원님들의
          {' '}
          <br />
          이용 후기를 확인하세요
        </div>
        <div className="row justify-content-center mg-custom pb-5">
          <div className="col pt-5">
            <div className="bg-white text-center mx-auto" style={{ width: 240, height: 364 }}>
              <div className="f-24 f-700 mt-3 pt-5">잰스</div>
              <div className="f-16 f-500 mx-auto mt-3 text-sub" style={{ width: 190 }}>
                꾸준히 운동을 못하는 편인데, 몇년째 하고 있어요.
                처음 상담할때도 꼼꼼하게 물어봐주시고 성향을 파악해주셨어요.
                원장님의 열정에 중간에 쉬었다가도 다시 시작하게 됩니다.
              </div>
            </div>
          </div>
          <div className="col pt-5">
            <div className="bg-white text-center mx-auto" style={{ width: 240, height: 364 }}>
              <div className="f-24 f-700 mt-3 pt-5">Shiny78</div>
              <div className="f-16 f-500 mx-auto mt-3 text-sub" style={{ width: 190 }}>
                원래 센터고를때는 그 선생님이 운동을 하시는 분인지를 잘 봐야하는데
                경험상 운동을 하셨던 분들이 확실히 더 잘 가르쳐주세요
                애니핏 원장님은 필라테스 뿐만아니라
                {' '}
                <span className="gyro">{gyrotonic}</span>
                {' '}
                및 운동을 좋아하시는
                분이라서 확실히 가르쳐주시는 디테일이 달라요
              </div>
            </div>
          </div>
          <div className="col pt-5">
            <div className="bg-white text-center mx-auto" style={{ width: 240, height: 364 }}>
              <div className="f-24 f-700 mt-3 pt-5">이혜연1938</div>
              <div className="f-16 f-500 mx-auto mt-3 text-sub" style={{ width: 190 }}>
                이전에 운동은 거의 안해봤던 초보였는데 지금 2년째 꾸준히
                이 센터에서 필라테스로 운동하게 됐어요~! 우선 원장쌤이 정말 친절하게 가르쳐주세요!!
                자세 하나하나 계속 잡아주시고 몸에 어떤부분이 약한지 맞춰서 알려주셔서 편했습니다:)
              </div>
            </div>
          </div>
          <div className="col pt-5">
            <div className="bg-white text-center mx-auto" style={{ width: 240, height: 364 }}>
              <div className="f-24 f-700 mt-3 pt-5">marble327</div>
              <div className="f-16 f-500 mx-auto mt-3 text-sub" style={{ width: 190 }}>
                동네 필라테스 이곳저곳을 전전하다 정착한지 2년되어갑니다.
                원장 선생님이 정말 성실하고 전문적으로 가르쳐주십니다.
                단순 운동을 한다기보다 운동을 통해 체형도 교정하고
                건강한 몸을 만들고 유지하는데 초점을 맞춘 수업을 진행해주세요.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-2 sm-none" />
  </div>
);

export default review;
