import React from 'react';
import './App.css';
import './assets/styles/style.css';
import './assets/styles/color.css';
import './assets/styles/reset.css';
import './assets/gyrotonic';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Instructor from './pages/Instructor';
import Course from './pages/Course';
import Center from './pages/Center';
import Nav from './components/common/Nav';
import Footer from './components/common/Footer';
import ScrollTop from './components/common/ScrollTop';
import Gyrotonic1 from './components/course/pages/gyrotonic1';
import Gyrotonic2 from './components/course/pages/gyrotonic2';
import Gyrokinesis1 from './components/course/pages/gyrokinesis1';
import Gyrokinesis2 from './components/course/pages/gyrokinesis2';
import Special from './components/course/pages/special';
import Update from './components/course/pages/update';

function App() {
  return (
    <Router>
      <ScrollTop />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/course" element={<Course />} />
        <Route path="/course/gyrotonic1" element={<Gyrotonic1 />} />
        <Route path="/course/gyrotonic2" element={<Gyrotonic2 />} />
        <Route path="/course/gyrokinesis1" element={<Gyrokinesis1 />} />
        <Route path="/course/gyrokinesis2" element={<Gyrokinesis2 />} />
        <Route path="/course/special" element={<Special />} />
        <Route path="/course/update" element={<Update />} />
        <Route path="/center" element={<Center />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
