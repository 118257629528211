import React from 'react';
import ImageSlide from './imageSlide';

const centerClass = () => (
  <div className="">
    <div className="col">
      <div className="w-80p mx-auto mt-3 w-80p w-xs-100p">
        <div className="text-black text-center f-36 f-sm-30 f-700 pt-5">Class</div>
        <ImageSlide />
      </div>
    </div>
  </div>

);

export default centerClass;
